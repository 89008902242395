import React, { useEffect } from "react";
import ExpensesCard from "./cards/ExpensesCard";
import IncomeCard from "./cards/IncomeCard";
import ProfitCard from "./cards/ProfitCard";
import tables from "../styles/tables.module.css";
import { useAtom } from "jotai";
import { authenticationAtom } from "../atoms/auth";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getAllTrips } from "../api/trips/tripsAPI";
import { addTrips, selectTrips } from "../features/tripsSlice";
import { getAllTrucks } from "../api/trucks/trucksAPI";
import { addTrucks } from "../features/truckSlice";
import useAuth from "../hooks/useAuth";
import {
  getAllMonthlyExpenses,
  getOwnerPersonalExpense,
} from "../api/expenses/expensesAPI";
import {
  addMonthlyExpenses,
  addOwnerExpenses,
  selectMonthlyExpenses,
  selectOwnerExpenses,
} from "../features/expensesSlice";
import TripsCompletedCard from "./cards/TripsCompletedCard";
import TripsProgressCard from "./cards/TripsProgressCard";
import TripsFailedCard from "./cards/TripsFailedCard";
import { getOwnerPersonalRevenue } from "../api/revenue/revenueAPI";
import {
  addOwnerRevenues,
  selectOwnerRevenues,
} from "../features/revenueSlice";

const TripMobileCard = ({
  trip: {
    truck_registration_number = "",
    driver = "",
    start_date = "",
    end_date = "",
    trip_routes = "",
    trip_status = "",
    total_trip_invoice = "",
    total_trip_expenses = "",
  },
  position,
}) => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const nameArray = driver.split(" ");
  const middleName = nameArray[1].charAt(0);

  const profit = total_trip_invoice - total_trip_expenses;

  return (
    <div className="shadow-md mt-4 border border-slate-800 px-2 py-2">
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">No</p>
        <p className="text-right">{position}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Truck</p>
        <p className="text-right">{truck_registration_number}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Driver</p>
        <p className="text-right capitalize">
          {nameArray[0]} {middleName} {nameArray[2]}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Start Date</p>
        <p className="text-right">{start_date}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">End Date</p>
        <p className="text-right">{end_date}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Routes</p>
        <p className="text-right">
          {trip_routes ? <span>{trip_routes.length}</span> : 0}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Status</p>
        <p className="text-right capitalize">{trip_status}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Invoices Amount</p>
        <p className="text-right">{formatter.format(total_trip_invoice)}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Expenses Amount</p>
        <p className="text-right">{formatter.format(total_trip_expenses)}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left font-semibold">Profit Amount</p>
        <p className="text-right">
          {formatter.format(profit)}
        </p>
      </div>
    </div>
  );
};

const OwnerOverview = () => {
  const [userData] = useAtom(authenticationAtom);
  const { userInfo: user } = userData;

  const dispatch = useDispatch();
  const auth = useAuth();
  const token = auth?.token || "";

  useEffect(() => {
    const getTrucks = async () => {
      const response = await getAllTrucks({ token });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addTrucks(response.data.data));
      }
    };

    const getTrips = async () => {
      const response = await getAllTrips({ token });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addTrips(response.data.data));
      }
    };

    const getExpenses = async () => {
      const response = await getOwnerPersonalExpense({
        owner_id: user?.id || "",
        token,
      });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addOwnerExpenses(response.data.data));
      } else {
        dispatch(addOwnerExpenses([]));
      }
    };

    const getRevenue = async () => {
      const response = await getOwnerPersonalRevenue({
        owner_id: user?.id || "",
        token,
      });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addOwnerRevenues(response.data.data));
      } else {
        dispatch(addOwnerRevenues([]));
      }
    };

    const getIndirectExpenses = async () => {
      const response = await getAllMonthlyExpenses({ token });

      if (response.ok && !isEmpty(response.data)) {
        dispatch(addMonthlyExpenses(response.data.data));
      }
    };

    getTrucks();
    getTrips();
    getExpenses();
    getRevenue();
    getIndirectExpenses();
  }, [dispatch]);

  const trips = useSelector(selectTrips);
  const expenses = useSelector(selectOwnerExpenses);
  const revenues = useSelector(selectOwnerRevenues);
  const indirectExpenses = useSelector(selectMonthlyExpenses);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "TZS",
  });

  const userTripsArray = trips.filter((trip) => {
    //filtering array value
    return trip.owner_id === user.id;
  });

  const userIndirectExpensesArray = indirectExpenses.filter((expense) => {
    //filtering array value
    return expense?.truck?.owner_id === user.id;
  });

  let invoicesTotalAmount = 0;
  let expenseTotalAmount = 0;
  let profitTotalAmount = 0;
  let personalExpensesTotalAmount = 0;
  let personalRevenueTotalAmount = 0;
  let balanceTotalAmount = 0;
  let unpaidAmount = 0;
  let availableBalance = 0;
  let indirectAmount = 0;

  if (userTripsArray.length !== 0) {
    for (const value of userTripsArray) {
      invoicesTotalAmount += value.total_trip_invoice;
      expenseTotalAmount += value.total_trip_expenses;
    }
  }

  if (expenses.length !== 0) {
    for (const value of expenses) {
      personalExpensesTotalAmount += value.amount;
    }
  }

  if (revenues.length !== 0) {
    for (const value of revenues) {
      personalRevenueTotalAmount += value.amount;
    }
  }

  if (userIndirectExpensesArray.length !== 0) {
    for (const value of userIndirectExpensesArray) {
      indirectAmount += value.amount;
    }
  }

  let amountArray = [];

  userTripsArray.map((trip) => {
    //filtering array value
    if (trip.total_trip_invoice > 0) {
      if (trip.trip_routes) {
        const routes = trip.trip_routes;

        routes.map((route) => {
          if (route.route_invoice) {
            amountArray.push(route.route_invoice.unpaid_amount);
          }
        });
      }
    }
    return 0;
  });

  if (amountArray !== 0) {
    unpaidAmount = amountArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
  }

  profitTotalAmount = invoicesTotalAmount - expenseTotalAmount - indirectAmount;
  balanceTotalAmount = personalRevenueTotalAmount + profitTotalAmount - personalExpensesTotalAmount;
  availableBalance = balanceTotalAmount - unpaidAmount;

  const renderTrips = () => {
    return (
      <div>
        <div className="block md:hidden">
          {userTripsArray.length !== 0 ? (
            <div>
              {userTripsArray.map((trip, index) => {
                const position = index + 1;
                return (
                  <TripMobileCard trip={trip} key={index} position={position} />
                );
              })}
            </div>
          ) : (
            <p className="text-center">User trucks has no trips</p>
          )}
        </div>
        <div className="hidden md:block">
          <div
            className={`bg-[#EEDEDE] h-10 text-center ${tables.ownerTripTable} rounded-t-md font-semibold`}
          >
            <p className="py-2">#</p>
            <p className="py-2">Truck</p>
            <p className="py-2">Start Date</p>
            <p className="py-2">End Date</p>
            <p className="py-2">Status</p>
            <p className="py-2">Routes</p>
            <p className="py-2">Invoices Amount</p>
            <p className="py-2">Expenses Amount</p>
            <p className="py-2">Profit Amount</p>
          </div>
          <div className="bg-[#F0F0F0] h-[256px] overflow-y-scroll">
            {renderTrip()}
          </div>
          <div className="h-16 pb-16 w-[100%] bg-gradient-to-r from-[#EEDEDE] to-[#F0F0F0] flex flex-row gap-2 justify-center rounded-b-md">
            <p className="text-base font-semibold">
              {userTripsArray.length || 0}
            </p>
            <p className="text-base font-semibold">TOTAL TRIPS TO DATE</p>
          </div>
        </div>
      </div>
    );
  };

  const renderTrip = () => {
    if (userTripsArray.length !== 0) {
      return (
        <div>
          {userTripsArray.map((trip, index) => {
            const position = index + 1;
            return (
              <div
                key={index}
                className={`h-10 bg-white ${tables.ownerTripTable} rounded-md text-sm my-2 mx-2`}
              >
                <p className="py-2 text-center">{position}</p>
                <p className="py-2 text-center">
                  {trip.truck_registration_number}
                </p>
                <p className="py-2 text-center">{trip.start_date}</p>
                <p className="py-2 text-center">{trip.end_date}</p>
                <p className="py-2 pl-7">
                  {trip.trip_status === "not_started" ? (
                    <span>Not started</span>
                  ) : trip.trip_status === "not_complete" ? (
                    <span>Failed</span>
                  ) : (
                    <span className="capitalize">{trip.trip_status}</span>
                  )}
                </p>
                <p className="py-2 text-center">
                  {trip.trip_routes ? (
                    <span>{trip.trip_routes.length}</span>
                  ) : (
                    0
                  )}
                </p>
                <p className="py-2 pl-6">
                  {formatter.format(trip.total_trip_invoice)}
                </p>
                <p className="py-2 pl-6">
                  {formatter.format(trip.total_trip_expenses)}
                </p>
                <p className="py-2 pl-6">
                  {formatter.format(
                    trip.total_trip_invoice - trip.total_trip_expenses
                  )}
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <p className="text-center">User trucks has no trips</p>;
    }
  };

  return (
    <div className="py-2 pl-4 pr-4 w-screen md:w-[calc(100vw-84px)] mx-auto">
      <div className="mt-2 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-4">
        <IncomeCard
          value={formatter.format(invoicesTotalAmount)}
          owner={true}
          car={false}
        />
        <ExpensesCard
          value={formatter.format(expenseTotalAmount)}
          owner={true}
          car={false}
          indirect={formatter.format(indirectAmount)}
        />
        <TripsCompletedCard
          trip={true}
          car={true}
          title={"PROFITS"}
          value={formatter.format(profitTotalAmount)}
        />
        <TripsProgressCard
          trip={true}
          car={true}
          title={"PERSONAL EXPENSES / REVENUE"}
          value={formatter.format(personalExpensesTotalAmount)}
          revenue={formatter.format(personalRevenueTotalAmount)}
        />
        <TripsFailedCard
          trip={true}
          car={true}
          title={"BALANCE"}
          balance={availableBalance}
          value={formatter.format(balanceTotalAmount)}
        />
        <ProfitCard
          title={"UNPAID INVOICES AMOUNT"}
          value={formatter.format(unpaidAmount)}
          owner={true}
          car={false}
        />
      </div>
      <div className="py-4 ">
        <h5>Total Trucks Trips ({userTripsArray.length})</h5>
        <div className="w-[100%] pb-8 mt-0">{renderTrips()}</div>
      </div>
    </div>
  );
};

export default OwnerOverview;
